import React from 'react';
import './fastKey.css'

const priceData = [
    {
        id: 1,
        title: 'Вызов мастера',
        price: 400
    },
    {
        id: 2,
        title: 'Вскрытие дверных замков',
        price: 550
    },
    {
        id: 3,
        title: 'Вскрытие автомобильных замков',
        price: 750
    },
    {
        id: 4,
        title: 'Вскрытие сейфов',
        price: 900
    },
]

const FastKey = () => {
    return (
        <div>
            <div className='fastKey__fastKey-title section__padding' id='price'>
                <h1 className="gradient__text">Услуги и цены</h1>
            </div>
            {priceData.map((item, index) => (
                <div className='fastKey__fastKey' key={item.id + index}>
                    <div className="fastKey__fastKey-content">
                        <div>
                            <p>Стоимость зависит от сложности замка</p>
                            <h3>{item.title}</h3>
                        </div>
                        <div>
                            <h3>От {item.price}грн.</h3>
                        </div>

                    </div>
                    <div className="fastKey__fastKey-btn">
                        <a href="tel:0937770070">
                        <button type='button'>Вызвать</button>
                        </a>
                    </div>
                </div>
            ))}

        </div>
    )
};

export default FastKey;
